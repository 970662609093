import React from 'react'
import Layout from '../components/layout'
import BobbyImg from '../images/bobby.svg'

const bobbyFrameStyles = {
	width: '92%',
	minHeight: '60vh',
	borderRadius: '1px'
}

const Bobby = () => {
	return (
		<Layout title="Bobby Bot">
			<div className="container-fluid m-0 p-0">
				<div className="my-row justify-content-center">
					<div className="col-sm-11 col-md-10 col-lg-9 m-0 p-md-0 p-sm-3">
						<div className="my-row">
							<h1 className="hero-title">
									90% aller jobsuchen beginnen bei google
							</h1>
						</div>
						<div className="my-row">
							<h2 className="text-tr hero-subtitle">
									Aber für dich ist Google for Jobs Neuland?
							</h2>
						</div>
						<div className="my-row my-4"/>{/* dummy row */}
					</div>
				</div>
				<div className="my-row justify-content-center mb-5 position-relative">
					<div className="col-sm-12 col-md-10 col-lg-9 m-0 p-0 m-0 p-0 iframe-container">
						<iframe id="bobby-frame" src="/bobby" frameBorder="0" style={bobbyFrameStyles}></iframe>
					</div>
				</div>
				<div className="my-row my-5"/>{/* dummy row */}
				<div className="my-row my-5"/>{/* dummy row */}
				<img className="bobby-img" src={BobbyImg} alt="Bobby the Bot"/>
			</div>
		</Layout>
	)
}
 
export default Bobby